//tutor marketplace (think abt how to stop ppl from making deals outside the website)

import React from "react";
import Menu from "./Menu";

function FindATutor() {


  return (
    <>
    <Menu/>
    </>
  );
}

export default FindATutor;
