import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
const auth = getAuth();

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(null);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const handleReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      setTimeout(() => {
        setRedirectToLogin(true);
      }, 2000);
    } catch (error) {
      setError(error.message);
    }
  };

  if (redirectToLogin) {
    window.location.href = '/login';
  }

  return (
    <>
      <h2>Password Reset</h2>
      {resetSent ? (
        <div>
          <p>Reset email sent. Check your inbox.</p>
        </div>
      ) : (
        <div>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleReset}>Reset Password</button>
        </div>
      )}
      {error && <p>{error}</p>}
    </>
  );
}

export default ForgotPassword;
