//daily login streak
//csr with duck reservations and endangered duck breeds
//coming soon

import React from "react";
import Menu from "./Menu";

function YourPond() {
  return (
    <>
      <Menu />
    </>
  );
}

export default YourPond;
