import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUpPage from "./components/SignUpPage.js";
import Home from "./components/Home.js";
import ForgotPassword from "./components/ForgotPassword.js";
import SignInPage from "./components/SignInPage.js";
import AskScio from "./components/AskScio.js";
import FindATutor from "./components/FindATutor.js";
import "./App.css";
import firebase from "firebase/compat/app";
import firebaseConfig from "./firebaseConfig.js";
import Pomodoro from "./components/Pomodoro.js";
import Flashcards from "./components/Flashcards.js";
import YourPond from "./components/YourPond.js";
import LandingPage from "./components/LandingPage.js";
import FirstPage from "./components/FirstPage.js";
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
       
      </Routes>
    </Router>
  );
}

export default App;
