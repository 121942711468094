import React from 'react';
import toptransparent from "../resources/logos/toptransparent.png";
import { getDatabase, ref, push, serverTimestamp } from "firebase/database";

function LandingPage() {
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const emailInput = event.target.email;
    const email = emailInput.value;

    // Initialize Firebase's Realtime Database
    const database = getDatabase();
    const emailsRef = ref(database, "emails");

    // Send the email to Firebase
    await push(emailsRef, {
      email: email,
      timestamp: serverTimestamp()
    });

    // Clear the email input and show a success message
    emailInput.value = "Email sent successfully!";
  };

  return (
    <div className="container">
      <a
        href="https://forms.gle/ZUWU8freWWEcoCYo6"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={toptransparent} alt="Logo" className="top-landing-img" />
      </a>
      <main className="poppins-medium" style={{ marginTop: '6em' }}>
        <p style={{ fontWeight: 'bold', color: '#1f2232' }}>COMING SOON</p>
        <h1 style={{ marginBottom: '0.5em', fontSize: '4em', color: '#1f2232' }}>Building the future of studying</h1>
        <form onSubmit={handleFormSubmit}>
          <input type="email" name="email" placeholder="Email Address" style={{ flex: 1 }} required />
          <button type="submit" style={{ fontSize: '1.5em', paddingLeft: '30px', paddingRight: '30px' }}>Submit</button>
        </form>
      </main>

      <style jsx>{`
        .poppins-medium {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-style: normal;
        }
        body {
          font-family: sans-serif;
          margin: 0;
          padding: 0;
          background-color: #fde8e9;
        }

        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 100vh;
          text-align: center;
          padding: 2em;
        }

        .top-landing-img {
          width: 300px;
          height: auto;
          margin-bottom: 5em;
        }

        main {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 1.5em;
        }

        form {
          display: flex;
          border: 2px solid #1f2232;
          border-radius: 5px;
          width: fit-content;
        }

        input[type="email"],
        button {
          padding: 12px;
          border: none;
          font-size: 1.2em;
        }

        input[type="email"] {
          padding: 20px;
          border: none;
          font-size: 1.5em;
          flex-grow: 1;
          border-radius: 5px 0 0 5px;
          background-color: #fde8e9;
          width: 400px;
        }

        button {
          background-color: #1f2232;
          color: white;
          cursor: pointer;
          border-radius: 0 2px 2px 0;
          outline: 2px solid #1f2232;
        }

        button:hover {
          background-color: #db5461;
        }
      `}</style>
    </div>
  );
}

export default LandingPage;