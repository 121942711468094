import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD3zJyaYEoo9IT7CpIfJRgGqap28uelBmI",
  authDomain: "scio-ai.firebaseapp.com",
  databaseURL: "https://scio-ai-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "scio-ai",
  storageBucket: "scio-ai.appspot.com",
  messagingSenderId: "22866637312",
  appId: "1:22866637312:web:6ead05d0b9476eb89d9ee5",
  measurementId: "G-W2K21XYB0G"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const scioDB = getFirestore(app);